import React, { useEffect, useMemo, useState } from "react";

import { Button, Form, message, Modal, Select, Typography } from "antd";
import TypeTag from "../TypeTag";
import { TermType, UserRole } from "../../enums";
import { Term, User } from "../../types";
import TermService from "../../services/term.service";
import { useGetCurrentUser } from "../../hooks/useGetCurrentUser";
import UserService from "../../services/user.service";

const { Paragraph, Link } = Typography;

enum ShareTermStep {
  selectingUsers = "selectingUsers",
  finalising = "finalising",
}

const ShareTermModal = ({
  term,
  termNumber,
  termType,
  isModalVisible,
  setIsModalVisible,
}: {
  term: Term;
  termNumber: string;
  termType: string;
  isModalVisible: boolean;
  setIsModalVisible: (value: boolean) => void;
}) => {
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState<User[]>([]);
  const [sharedWithUsersIds, setSharedWithUsersIds] = useState<string[]>([]);
  const [step, setStep] = useState<ShareTermStep>(ShareTermStep.selectingUsers);
  const [form] = Form.useForm();

  const termService = useMemo(() => new TermService(), []);
  const userService = useMemo(() => new UserService(), []);

  const { user } = useGetCurrentUser();

  const fetchUsers = async () => {
    // filter users by the term companyId and not by current user's company id because
    // e.g. if a PL admin is sharing, they should be able to share the transaction
    // with the term's club's users, not PL's users
    const users = await userService.getUsers(
      // todo rebecca's request - sharing with club's members - temporarily hidden
      // `/?$filter=(companyId eq ${term.company.id} and id ne ${user.id})`,
      // sharing with external users only
      `/?$filter=(role eq 'legal')`,
    );

    setUsers(users.data);

    const sharedWithUsers = users.data
      .filter((u: User) =>
        u.termsShared.map((ts: Term) => ts.id).includes(term.id),
      )
      .map((u: User) => u.id);

    form.setFieldValue("sharedWithUsers", sharedWithUsers);

    setSharedWithUsersIds(sharedWithUsers);
  };

  useEffect(() => {
    if (user && user.company && user.company.id) {
      fetchUsers();
    }
  }, [user]);

  const onConfirm = async (shareLink: boolean) => {
    setLoading(true);
    const hide = message.loading(
      sharedWithUsersIds.length === 0 ? "Removing..." : "Sharing...",
      1.0,
    );

    try {
      const termUpdated = await termService.updateTermSharedWith({
        id: term.id,
        sharedWith: sharedWithUsersIds,
      });

      if (!termUpdated) {
        throw Error("There was a problem during the sharing operation.");
      }

      if (shareLink) {
        try {
          const emailAddresses = sharedWithUsersIds.map(
            (id) => users.find((user) => user.id == id)?.email,
          );

          await termService.shareLink({
            termId: term.id,
            emailAddresses,
          });
        } catch (err) {
          console.log("err sharing link", err);
        }
      }
      hide();
      message.success(
        sharedWithUsersIds.length === 0
          ? "Removed successfully"
          : "Shared successfully.",
      );
      setIsModalVisible(false);
    } catch (err) {
      console.log(err);
    }
  };

  const onFormFinish = () => {
    setSharedWithUsersIds(
      form.getFieldValue("sharedWithUsers").flatMap((id: string) => id),
    );
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setStep(ShareTermStep.selectingUsers);
  };

  const getActionButtons = () => {
    switch (step) {
      case ShareTermStep.selectingUsers:
        return [
          sharedWithUsersIds.length > 0 && (
            <Button
              key="remove-users"
              onClick={() => {
                form.setFieldValue("externalUsers", []);
                setSharedWithUsersIds([]);
                setStep(ShareTermStep.finalising);
              }}
            >
              Remove all users
            </Button>
          ),
          <Button
            key="submit"
            loading={loading}
            type="primary"
            // @ts-ignore
            onClick={() => {
              if (step === ShareTermStep.selectingUsers) {
                setStep(ShareTermStep.finalising);
                onFormFinish();
              }
            }}
          >
            Confirm
          </Button>,
        ];
      case ShareTermStep.finalising:
        if (sharedWithUsersIds.length === 0) {
          return [
            <Button
              key="submit-without-email"
              type="primary"
              loading={loading}
              onClick={() => onConfirm(true)}
            >
              Confirm
            </Button>,
          ];
        }
        return [
          <Button
            key="submit-with-email"
            type="primary"
            loading={loading}
            onClick={() => onConfirm(true)}
          >
            Grant access and send email
          </Button>,
          <Button
            key="submit-without-email"
            type="primary"
            loading={loading}
            onClick={() => onConfirm(false)}
          >
            Grant access only
          </Button>,
        ];
    }
  };

  return (
    // @ts-ignore
    <Modal
      title="Share Transaction"
      width={750}
      open={isModalVisible}
      onCancel={handleCancel}
      footer={[
        <Button key="back" onClick={handleCancel} loading={loading}>
          Cancel
        </Button>,
        ...getActionButtons(),
      ]}
    >
      {step === ShareTermStep.selectingUsers ? (
        <Form
          form={form}
          name="shareTerm"
          layout="vertical"
          onFinish={onFormFinish}
          scrollToFirstError
        >
          <Paragraph>
            You are about to share the <TypeTag type={termType as TermType} />
            transaction {termNumber}. Please select the user(s) you want to
            share the transaction with.
            <br />
            If a person is not visible in the list below, they must first be
            invited to the Commercial Databank as an External user. <br />
            To remove access from a user, remove them from list and click
            Confirm.
          </Paragraph>
          <Form.Item
            name="sharedWithUsers"
            tooltip="Select external users to share this transaction with."
            label="Users"
            rules={[{ required: true }]}
          >
            <Select
              filterOption={(input, option) =>
                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              mode="multiple"
              placeholder="Please select"
              style={{ width: "100%" }}
              options={users.map((user) => ({
                value: user.id,
                label: user.name,
              }))}
            />
          </Form.Item>
        </Form>
      ) : (
        <div>
          {sharedWithUsersIds.length > 0 ? (
            <>
              <Paragraph>
                You have chosen to grant readonly access to this for the
                following user(s):
              </Paragraph>

              <Paragraph>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  {sharedWithUsersIds.map((selUserId) => {
                    const userFound = users.find(
                      (user) => user.id === selUserId,
                    );
                    return (
                      <Link
                        key={userFound?.id}
                        href={`mailto:${userFound.email}`}
                      >
                        {userFound?.email}
                      </Link>
                    );
                  })}
                </div>
              </Paragraph>

              <Paragraph>
                Would you like to send an email the user(s) with a link to the
                transaction?
              </Paragraph>
            </>
          ) : (
            <>
              <Paragraph>
                No external users will have access to this Transaction.
              </Paragraph>
            </>
          )}
        </div>
      )}
    </Modal>
  );
};

export default ShareTermModal;
