import { Select } from "antd";
import React from "react";

const { Option } = Select;

let matterOptions = [
  "Loan",
  "Official partner",
  "Front of shirt sponsorship",
  "Sleeve sponsorship",
  "Kit supplier",
  "Training kit sponsor",
  "Tour/Match agreements",
  "Ambassador agreements",
  "Stadium naming rights",
  "Training ground naming rights",
  "In stadium advertising - fixed position",
  "In stadium advertising - LED panels",
  "Inventory supplier to the Club",
  "Service provider to the Club",
  "Digital integration arrangement (website / social media co-branding between Club and contracting party)",
  "Other",
];

const getSubjectMatterOptions = () =>
  matterOptions.map((matterOption) => (
    <Option key={matterOption} value={matterOption}>
      {matterOption}
    </Option>
  ));
export default getSubjectMatterOptions;
